import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-3-icon.svg"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import "slick-carousel/slick/slick.css";


const ButtonContainer = tw.div`text-center`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`bg-primary-200 text-white shadow-lg hocus:bg-white hocus:text-primary-800 items-center font-secondary mt-8 md:mt-8 text-sm text-center inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-200`;

const TestimonialsSlider = styled(Slider)`
  ${tw`flex mt-16 mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-gray-100 rounded-lg text-gray-900`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full`
const QuoteContainer = tw.div`relative`
const QuoteIcon = tw(QuoteIconBase)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`
const CustomerInfoAndControlsContainer = tw.div`mt-8 flex items-center flex-col sm:flex-row justify-center text-center sm:text-left`
const CustomerImage = tw.img`w-12 h-12 rounded-full`
const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-4 flex flex-col`
const CustomerName = tw.span`text-lg font-semibold`
const CustomerProfile = tw.span`text-sm font-normal text-gray-700`
const ControlsContainer = tw.div`sm:ml-auto flex`
const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-primary-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;

const contentful = require('contentful');

const client = contentful.createClient({
  space: '9x1luxhb97ml',
  accessToken: '1-O2swjKcGwyoYFwRygyb8HlHmiLClCnK9rO5yKU-PU'
})

const query = `
query {
  testimonialCollection { 
    items{
      testimonial
      image { url }
      
    } 
  }
}
`;

function App(){
  const [testimonials, setItem] = useState(null);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/9x1luxhb97ml?order=fields.name&content_type=cat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer 1-O2swjKcGwyoYFwRygyb8HlHmiLClCnK9rO5yKU-PU",
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        console.log(data)
        if (errors) {
          console.error(errors);
        }
        setItem(data.testimonialCollection.items);

      });

  }, []);

 const [sliderRef, setSliderRef] = useState(null)

 if (testimonials != null && testimonials.length > 0 ){
  console.log("testimonials", testimonials.length)

  const subheading = ""
  const heading = "Testimonials"
  const description = "Hear from our customers"
 
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        {!testimonials ? (<span>Loading...</span>) : (
        <TestimonialsSlider arrows={false} ref={setSliderRef}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <QuoteContainer>
                <QuoteIcon />
                <Quote>
                  {testimonial.testimonial}
                </Quote>
              </QuoteContainer>
              <CustomerInfoAndControlsContainer>
                <CustomerImage src={testimonial.image.url} />
                <CustomerNameAndProfileContainer>
                  <CustomerName>
                    {testimonial.customerName}
                  </CustomerName>
                  {/* <CustomerProfile>
                    {testimonial.customerProfile}
                  </CustomerProfile> */}
                </CustomerNameAndProfileContainer>
                <ControlsContainer>
                  <ControlButton onClick={sliderRef?.slickPrev}>
                    <ArrowLeftIcon className="icon" />
                  </ControlButton>
                  <ControlButton>
                    <ArrowRightIcon className="icon" onClick={sliderRef?.slickNext}/>
                  </ControlButton>
                </ControlsContainer>
              </CustomerInfoAndControlsContainer>
            </Testimonial>
          ))}
        </TestimonialsSlider>
        )}
        <ButtonContainer>
        <PrimaryButton buttonRounded="true" as="a" target="_blank" href="https://www.google.com/search?q=the+property+people&ei=bYzgYPS_HpTbtQbwx4b4CQ&gs_ssp=eJzj4tVP1zc0zLJMKc4xt8gxYLRSNaiwsEixTDJPM7FIM05MtjS0tDKoSLZINbJMSjRMTDM3MklNMvISLslIVSgoyi9ILSqpVChIzS_ISQUANJoXNg&oq=the+property+peo&gs_lcp=Cgdnd3Mtd2l6EAMYADILCC4QxwEQrwEQkwI6BwgAEEcQsAM6BQgAEJECOggIABCxAxCDAToLCC4QsQMQxwEQowI6CAguELEDEIMBOggILhDHARCjAjoFCAAQsQM6BAgAEEM6BAguEEM6BQguELEDOgIIADoICC4QxwEQrwE6AgguOgcILhBDEJMCOgcILhCxAxBDOggILhCxAxCTAjoLCAAQsQMQgwEQyQM6BQgAEMkDSgQIQRgAUJu1IVjSySFg_tAhaAFwAngAgAF3iAHQCpIBBDE2LjGYAQCgAQGqAQdnd3Mtd2l6yAEIwAEB&sclient=gws-wiz#lrd=0x88d9b7f48f3ac919:0xc8e29ba1af724eb2,1,,,">
              See More Reviews
            </PrimaryButton>        
        </ButtonContainer>     
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
}
  
    return null
  
};

export default App;
