import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line


import Home from "pages/Home.js";
import About from "pages/AboutUs.js";
import ClaimTypes from "pages/Claims.js";
import Contact from "pages/ContactUs.js";
import ContactThankYou from "pages/ContactThankYou";
import LandingPage from "pages/LandingPage.js";
import Blog from "pages/Blog.js";
import Posts from "pages/Posts.js";
import PrivacyPolicy from "pages/PrivacyPolicy.js"
import { BrowserRouter as Router, Link, useParams,  Switch, Route } from "react-router-dom";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
     
        <Route path="/about">
          <About />
        </Route>
        <Route path="/claim-types">
          <ClaimTypes />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/landingpage">
          <LandingPage />
        </Route>
        <Route path="/thank-you">
          <ContactThankYou />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/:id" children={<Posts />} />
        <Route path="/">
          <Home />
        </Route>
     
      </Switch>
    </Router>
  );
}
