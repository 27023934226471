import React, {useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useParams,  Switch, Route } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Features from "components/features/DashedBorderSixFeatures.js"

import { Container, ContentWithPaddingXl } from "components/misc/Layouts";

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import Moment from 'react-moment';

const PostText = tw.div`flex-1 px-6 py-8` 
const PostTitle = tw.h4`text-3xl text-center m-6 font-bold text-gray-900`;
const PostImage = tw.img`sm:w-full md:w-1/2 justify-items-center items-center mx-auto`;
const PostDate = tw.div`flex-1 text-center my-6`;

const contentful = require('contentful');

const client = contentful.createClient({
  space: '9x1luxhb97ml',
  accessToken: '1-O2swjKcGwyoYFwRygyb8HlHmiLClCnK9rO5yKU-PU'
})

function convertDate(date){
    var d = new Date(date);
    return d.toDateString();
  }

function App() {
    let { id } = useParams();
    const [post, setPost] = useState(null);
    useEffect(() => {
        client.getEntries({
            content_type: 'blogPost',
            'fields.slug[in]': id,
          }).then(function (entry){

          setPost(entry.items[0].fields);
          })
    }, []);
    
    console.log("here is my post set", post);

      
      
        return (
            <AnimationRevealPage>
            <Header />
            <Container>
              <ContentWithPaddingXl>
            <div>
            <>
            {!post ? (<span>Loading...</span>) : (
             <div>
                
                <PostImage src={post.heroImage.fields.file.url}></PostImage>
                <PostTitle>{post.title}</PostTitle>
                <PostDate><Moment format="D MMM, YYYY">{post.publishDate}</Moment></PostDate>
                <PostText>{post.description}</PostText>
                <PostText>{post.body}</PostText>
                </div>
              )}
      
            </>
            </div>
            </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
        )
}

export default App;
